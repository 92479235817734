import React, {Component} from 'react';
// import {BrowserRouter as Router} from 'react-router-dom';

import '../Stylesheets/App.css';
import '../Stylesheets/Elements/Links.css';
import '../Stylesheets/Elements/Scrollbar.css';
// import Main from "./Main";
// import Splash from "./Splash";
import TempSplash from "./TempSplash";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showSplash: window.location.pathname === '/'
        }
    }

    render() {
        return (
            <TempSplash/>
            // <Router>
            //     {
            //         this.state.showSplash ?
            //             <Splash onEnter={() => this.setState({showSplash: false})}/> :
            //             <Main/>
            //     }
            // </Router>
        );
    }
}

export default App;

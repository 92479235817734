import React, {Component} from 'react';

import background from '../Resources/hug.png';
import '../Stylesheets/Splash.css'

class TempSplash extends Component {
    render() {
        return (
            <div id="splash_wrapper" style={{backgroundImage: 'url(' + background + ')'}}>
                <div id="title_wrapper">
                    <h1 id="page_title">LOUISA YUNG</h1>
                    <h2 id="page_subtitle">COME BACK LATER</h2>
                </div>
            </div>
        );
    }
}

export default TempSplash;
